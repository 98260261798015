<template>
  <div>
    <div>
      <h3 class="card-label">
        {{ $t('bill_of_material.bill_of_material') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>


      <b-tabs content-class="mt-3" class="nav-custom-link">
        <b-tab :title="$t('bill_of_material.basic_information')" @click="toggleTab('basic_information')" active>
          <div class="row">
            <div class="col-md-6">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('bill_of_material.name') }}<span class="text-danger">*</span></label>
                    <input type="text" v-model="data.name" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.name[0] }}
                                    </span>
                  </div>
                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('bill_of_material.item') }}<span class="text-danger">*</span></label>

                    <div class="d-flex search-item-group input-group">
                      <select name="" id="" v-model="f_by" class="custom-select">
                        <option value="name" selected>{{ $t('items.name') }}</option>
                        <option value="sku_code">{{ $t('items.sku_code') }}</option>
                      </select>
                      <multiselect v-model="item" style="width: 60%;"
                                   id="item_id"
                                   :placeholder="$t('bill_of_material.item')"
                                   :class="validation && validation.item_id ? 'is-invalid' : ''"
                                   :label="f_by?f_by:'sku_code'"
                                   track-by="id"
                                   :options="items_manufacture"
                                   @input="getItemsForRepeater"
                                   :multiple="false"
                                   :taggable="false"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false"
                                   :internal-search="false"
                                   @search-change="getItemsManufacture(f_by, $event)">
                      </multiselect>

                      <div class="input-group-prepend">
                        <a class="btn btn-primary" href="/manufactories/material-list/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                      </div>
                    </div>
                    <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('bill_of_material.item') }}.</span>
                    <span v-if="validation && validation.item_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.item_id[0] }}
                                    </span>
                  </div>
                  <div class="col-lg-12 mb-5" v-if="item">
                    <label>{{ $t('bill_of_material.item_details') }}</label>
                    <div class="d-flex">
                      <img v-if="item && item.image_url" :src="item.image_url" style="width: 70px; height: 70px;" alt="">
                      <div class="pl-2 pr-2 text-dark">
                        <p class="mb-1" v-if="item && item.sku_code">{{ $t('items.sku_code') }}: {{ item.sku_code }}</p>
                        <p class="mb-1" v-if="item && item.name">{{ $t('items.name') }}: {{ item.name }}</p>
                        <p class="mb-1" v-if="item && item.sale_price">{{ $t('items.sale_price') }}: {{ item.sale_price }}</p>
                        <p class="mb-1" v-if="item && item.purchase_price">{{ $t('items.purchase_price') }}: {{ item.purchase_price }}</p>
                        <p class="mb-1" v-if="item && item.sum_available_qty">{{ $t('items.sum_available_qty') }}: {{ item.sum_available_qty }}</p>
                      </div>

                    </div>

                  </div>
                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('bill_of_material.company') }}</label>
                    <input type="text" disabled v-model="company_name" class="form-control" :class="validation && validation.company_id ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.company_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.company_id[0] }}
                                    </span>
                  </div>
                </div>
              </div>
              <div class="card card-custom mt-5">
                <div class="card-body row">
                  <div class="col-lg-6 mb-5">
                    <b-form-checkbox size="lg" v-model="data.allow_alternative_item" name="check-button" switch>{{ $t('bill_of_material.allow_alternative_item') }}</b-form-checkbox>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <b-form-checkbox size="lg" v-model="data.is_default" name="check-button" switch>{{ $t('bill_of_material.is_default') }}</b-form-checkbox>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch>{{ $t('bill_of_material.is_active') }}</b-form-checkbox>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <b-form-checkbox size="lg" v-model="data.with_operation" name="check-button" switch>{{ $t('bill_of_material.with_operation') }}</b-form-checkbox>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <b-form-checkbox size="lg" v-model="data.rate_sub_assembly_based_bom" name="check-button" switch>{{ $t('bill_of_material.rate_sub_assembly_based_bom') }}</b-form-checkbox>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <b-form-checkbox size="lg" v-model="data.allow_multi_inventory" name="check-button" switch>{{ $t('bill_of_material.allow_multi_inventory') }}</b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('bill_of_material.unit') }}</label>
                    <div class="input-group mb-3">
                      <multiselect v-model="unit"
                                   :placeholder="$t('bill_of_material.unit')"
                                   label="name"
                                   track-by="id"
                                   :options="units"
                                   :multiple="false"
                                   :taggable="true"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false">
                      </multiselect>
                      <div class="input-group-prepend">
                        <a class="btn btn-primary" href="/settings/units" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                      </div>
                    </div>
                    <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('bill_of_material.unit') }}.</span>
                    <span v-if="validation && validation.unit_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.unit_id[0] }}
                                    </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('bill_of_material.quantity') }}</label>
                    <input type="number" min="1" v-model="data.quantity" class="form-control" :class="validation && validation.quantity ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.quantity" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.quantity[0] }}
                                    </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('bill_of_material.actual_quantity') }}</label>
                    <input type="number" min="0" v-model="data.actual_quantity" class="form-control" :class="validation && validation.actual_quantity ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.actual_quantity" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.actual_quantity[0] }}
                                    </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label for="inventories">{{ $t('bill_of_material.inventory') }}</label>
                    <div class="input-group mb-3">
                      <multiselect v-model="inventory"
                                   id="inventories"
                                   :placeholder="$t('bill_of_material.inventory')"
                                   label="name"
                                   track-by="id"
                                   :options="inventories"
                                   :multiple="false"
                                   :taggable="false"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false">
                      </multiselect>
                      <div class="input-group-prepend">
                        <a class="btn btn-primary" href="/settings/inventories/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                      </div>
                    </div>
                    <span v-if="validation && validation.inventory_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.inventory_id[0] }}
                                    </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('bill_of_material.branch') }}</label>
                    <div class="input-group mb-3">
                      <multiselect v-model="branch"
                                   :placeholder="$t('bill_of_material.branch')"
                                   label="name"
                                   track-by="id"
                                   :options="branches"
                                   :multiple="false"
                                   :taggable="false"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false">
                      </multiselect>
                      <div class="input-group-prepend">
                        <a class="btn btn-primary" href="/settings/branches/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                      </div>
                    </div>
                    <span v-if="validation && validation.branch_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.branch_id[0] }}
                                    </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('bill_of_material.rate_material') }}</label>
                    <select name="" id="rate_material" v-model="data.rate_material" @input="getItemPrice" class="custom-select" :class="validation && validation.rate_material ? 'is-invalid' : ''">
                      <option v-for="row in rate_materials" :value="row.id" :key="row.id">{{ row.title }}</option>
                    </select>
                    <span v-if="validation && validation.rate_material" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.rate_material[0] }}
                                    </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('bill_of_material.currency') }}</label>
                    <div class="input-group mb-3">
                      <select name="" id="currency_id" v-model="data.currency_id" class="custom-select" :class="validation && validation.currency_id ? 'is-invalid' : ''">
                        <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                      </select>
                      <div class="input-group-prepend">
                        <a class="btn btn-primary" href="/settings/currencies" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                      </div>
                    </div>
                    <span v-if="validation && validation.currency_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.currency_id[0] }}
                                    </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('exchange_rate') }}</label>
                    <input type="number" v-model="data.exchange_rate" step="0.01" min="0" class="form-control" :class="validation && validation.exchange_rate ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.exchange_rate" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.exchange_rate[0] }}
                                </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('bill_of_material.transfer_manual') }}</label>
                    <select name="" id="transfer_manual" v-model="data.transfer_manual" class="custom-select" :class="validation && validation.transfer_manual ? 'is-invalid' : ''">
                      <option v-for="row in transfer_manuals" :value="row.id" :key="row.id">{{ row.title }}</option>
                    </select>
                    <span v-if="validation && validation.transfer_manual" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.transfer_manual[0] }}
                                    </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('bill_of_material.routing') }}</label>
                    <div class="input-group mb-3">
                      <multiselect v-model="routing_obj"
                                   :placeholder="$t('bill_of_material.routing')"
                                   label="name"
                                   track-by="id"
                                   :options="routing_list"
                                   @input="getRoutingRepeater"
                                   :multiple="false"
                                   :taggable="false"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false">
                      </multiselect>
                      <div class="input-group-prepend">
                        <a class="btn btn-primary" href="/manufactories/routing-management/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                      </div>
                    </div>
                    <span v-if="validation && validation.routing_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.routing_id[0] }}
                                    </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('bill_of_material.status') }}</label>
                    <select name="" id="status" v-model="data.status" class="custom-select" :class="validation && validation.status ? 'is-invalid' : ''">
                      <option v-for="row in status_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                    </select>
                    <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.status[0] }}
                                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-lg-12">
              <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                <h6 class="mt-2">{{ $t('bill_of_material.operations') }}</h6>
                <button type="button" class="btn btn-primary" @click="addRepeater"><i class="fas fa-plus"></i>{{ $t('add_more') }}</button>
              </div>
              <div class="table-responsive bg-white">
                <table class="table table-custom-padding">
                  <thead>
                  <tr>
                    <th scope="col">{{ $t('bill_of_material.sequence_id') }}</th>
                    <th scope="col">
                      <div class="d-flex justify-content-between">
                        <span>{{ $t('bill_of_material.operation') }}</span>
                        <a href="/manufactories/operation-management/create" target="_blank" class="btn btn-primary btn-sm p-1">
                          <i class="fa fa-plus add-new"></i>
                        </a>
                      </div>
                    </th>
                    <th scope="col">
                      <div class="d-flex justify-content-between">
                        <span>{{ $t('bill_of_material.workstation') }}</span>
                        <a href="/settings/units" target="_blank" class="btn btn-primary btn-sm p-1">
                          <i class="fa fa-plus add-new"></i>
                        </a>
                      </div>
                    </th>
                    <th scope="col">{{ $t('bill_of_material.operation_time_minutes') }}</th>
                    <th scope="col">{{ $t('bill_of_material.operation_price_per_hour') }}</th>
                    <th scope="col">{{ $t('bill_of_material.cost') }}</th>
                    <th scope="col">{{ $t('bill_of_material.description') }}</th>
                    <th scope="col">{{ $t('actions') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in sub_operations" :key="index">
                    <td>
                      <input type="number" min="0" disabled v-model="item.sequence_id" class="form-control" :placeholder="$t('bill_of_material.sequence_id')"/>
                      <span v-if="validation && validation['operations.'+index+'.sequence_id']" class="fv-plugins-message-container invalid-feedback">
                                                    {{ validation['operations.' + index + '.sequence_id'][0] }}
                                                </span>
                    </td>
                    <td>
                      <multiselect v-model="item.operation"
                                   :placeholder="$t('bill_of_material.operation')"
                                   label="name"
                                   track-by="id"
                                   :options="operations"
                                   :multiple="false"
                                   :taggable="false"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false"
                                   @input="selectItem(index, 'operation')">
                      </multiselect>
                    </td>
                    <td>
                      <multiselect v-model="item.workstation"
                                   :placeholder="$t('bill_of_material.workstation')"
                                   label="name"
                                   track-by="id"
                                   :options="workstations"
                                   :multiple="false"
                                   :taggable="false"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false"
                                   @input="selectItem(index, 'workstation')">
                      </multiselect>
                    </td>
                    <td>
                      <input type="number" min="0" v-model="item.operation_time_minutes" class="form-control" :placeholder="$t('bill_of_material.operation_time_minutes')"/>
                    </td>
                    <td>
                      <input type="number" min="0" v-model="item.operation_price_per_hour" class="form-control" :placeholder="$t('bill_of_material.operation_price_per_hour')"/>
                    </td>
                    <td>
                      <input type="number" min="0" disabled v-model="item.cost" class="form-control" :placeholder="$t('bill_of_material.cost')"/>
                    </td>
                    <td>
                      <textarea v-model="item.description" class="form-control" :placeholder="$t('bill_of_material.description')"></textarea>
                    </td>

                    <td>
                      <button type="button" v-if="item.use_delete" class="btn btn-link mr-2" @click="removeRepeater(index)"><i class="fas fa-trash text-danger"></i></button>
                    </td>
                  </tr>


                  </tbody>
                </table>
              </div>

            </div>
          </div>
          <div class="row mt-5">
            <div class="col-lg-12">
              <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                <h6 class="mt-2">{{ $t('bill_of_material.materials') }}</h6>
                <button type="button" class="btn btn-primary" @click="addRepeaterItem"><i class="fas fa-plus"></i>{{ $t('add_more') }}</button>
              </div>
              <div class="table-responsive bg-white">
                <table class="table table-row-bordered table-custom-padding" @keyup.alt.enter="addRepeaterItem" @keyup.alt.46="removeRepeaterItem">
                  <thead>
                  <tr>
                    <th width="250px">{{ $t('bill_of_material.item_details') }}</th>
                    <th width="300px">{{ $t('bill_of_material.item') }}</th>
                    <th width="150px">{{ $t('bill_of_material.origin_quantity') }}</th>
                    <th width="150px">{{ $t('bill_of_material.required_quantity') }}</th>
                    <th width="200px">
                      <div class="d-flex justify-content-between">
                        <span>{{ $t('bill_of_material.unit') }}</span>
                        <a href="/settings/units" target="_blank" class="btn btn-primary btn-sm p-1">
                          <i class="fa fa-plus add-new"></i>
                        </a>
                      </div>
                    </th>
                    <th width="200px">
                      <div class="d-flex justify-content-between">
                        <span>{{ $t('bill_of_material.inventory') }}</span>
                        <a href="/settings/inventories" target="_blank" class="btn btn-primary btn-sm p-1">
                          <i class="fa fa-plus add-new"></i>
                        </a>
                      </div>
                    </th>
                    <th width="150px">{{ $t('bill_of_material.reserved') }}</th>
                    <th width="150px">{{ $t('bill_of_material.price') }}</th>
                    <th width="150px">{{ $t('bill_of_material.amount') }}</th>
                    <th>{{ $t('actions') }}</th>
                  </tr>

                  </thead>
                  <tbody>
                  <tr v-for="(row, index) in sub_items" :key="index">
                    <td>
                      <a :id="'popover-target-'+index" href="javascript:;" v-if="row.item" class="d-flex cursor-pointer">
                        <img :src="row.item.image_url" style="width: 70px; height: 70px;" alt="">
                        <div class="pl-2 pr-2 text-dark">
                          <p class="mb-1" v-if="row.item.sku_code">{{ $t('items.sku_code') }}: {{ row.item.sku_code }}</p>
                          <p class="mb-1" v-if="row.item.name">{{ $t('items.name') }}: {{ row.item.name }}</p>
                          <p class="mb-1" v-if="row.item.sale_price">{{ $t('items.sale_price') }}: {{ row.item.sale_price }}</p>
                          <p class="mb-1" v-if="row.item.purchase_price">{{ $t('items.purchase_price') }}: {{ row.item.purchase_price }}</p>
                          <p class="mb-1" v-if="row.item.sum_available_qty">{{ $t('items.sum_available_qty') }}: {{ row.item.sum_available_qty }}</p>
                        </div>
                      </a>
                    </td>
                    <td>
                      <div class="d-flex search-item-group" v-b-tooltip.hover.leftbottom :title="$t('type_at_least_three_letters') + ' ' + $t('bill_of_material.item')">
                        <select name="" v-model="row.f_by" class="custom-select" style="width: 100px;">
                          <option value="name" selected>{{ $t('items.name') }}</option>
                          <option value="sku_code">{{ $t('items.sku_code') }}</option>
                        </select>
                        <multiselect v-model="row.item"
                                     :placeholder="$t('bill_of_material.item')"
                                     :label="row.f_by? row.f_by:'name'"
                                     track-by="id"
                                     :options="items_repeater_select"
                                     @input="checkItemSelected(index, $event)"
                                     :multiple="false"
                                     :taggable="false"
                                     :show-labels="false"
                                     :show-no-options="false"
                                     :show-no-results="false">
                        </multiselect>
                      </div>

                    </td>

                    <td><input v-model="row.origin_quantity" type="number" class="form-control"></td>
                    <td>
                      <input v-model="row.required_quantity" type="number" class="form-control">
                      <template v-if="material_validations && row.item">
                                                        <span v-if="material_validations['message.'+row.item.id]" class="text-danger">
                                                        {{ material_validations['message.' + row.item.id] }}
                                                    </span>
                      </template>


                    </td>

                    <td>
                      <multiselect v-model="row.unit" v-b-tooltip.hover.leftbottom :title="$t('type_at_least_three_letters') + ' ' + $t('bill_of_material.unit')"
                                   :placeholder="$t('bill_of_material.unit')"
                                   label="name"
                                   track-by="id"
                                   :options="units"
                                   :multiple="false"
                                   :taggable="false"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false">
                      </multiselect>
                    </td>
                    <td>
                      <multiselect v-model="row.inventory"
                                   :placeholder="$t('bill_of_material.inventory')"
                                   label="name"
                                   track-by="id"
                                   :options="inventories"
                                   :multiple="false"
                                   :taggable="false"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false">
                      </multiselect>
                    </td>
                    <td><input v-model="row.reserved" type="number" class="form-control"></td>
                    <td><input v-model="row.price" type="number" class="form-control"></td>
                    <td><input v-model="row.amount" type="number" class="form-control"></td>


                    <td>
                      <v-icon style="color: #dc3545;" small v-if="sub_items.length > 1" @click="removeRepeaterItem(index)">mdi-delete</v-icon>

                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="mt-5">
            <b-tabs content-class="mt-3" class="nav-custom-link">
              <b-tab :title="$t('notes')">
                <div class="card card-custom">
                  <div class="card-body row">
                    <div class="col-lg-12 mb-5">
                      <label>{{ $t('bill_of_material.description') }}</label>
                      <textarea v-model="data.description" class="form-control" :class="validation && validation.description ? 'is-invalid' : ''"></textarea>
                      <span v-if="validation && validation.description" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.description[0] }}
                            </span>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>

        </b-tab>
        <b-tab :title="$t('bill_of_material.scrap')" @click="toggleTab('scrap')">
          <div class="row mt-5">
            <div class="col-lg-12">
              <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                <h6 class="mt-2">{{ $t('bill_of_material.scrap_and_process_loss') }}</h6>
                <button type="button" class="btn btn-primary" @click="addRepeaterScrapItem"><i class="fas fa-plus"></i>{{ $t('add_more') }}</button>
              </div>
              <div class="table-responsive bg-white">
                <table class="table table-row-bordered table-custom-padding" @keyup.alt.enter="addRepeaterScrapItem" @keyup.alt.46="removeRepeaterScrapItem">
                  <thead>
                  <tr>
                    <th width="250px">{{ $t('bill_of_material.item_details') }}</th>
                    <th width="300px">{{ $t('bill_of_material.item') }}</th>
                    <th width="150px">{{ $t('bill_of_material.quantity') }}</th>
                    <th width="150px">{{ $t('bill_of_material.is_waste') }}</th>
                    <th width="200px">{{ $t('bill_of_material.unit') }}</th>
                    <th width="150px">{{ $t('bill_of_material.price') }}</th>
                    <th width="150px">{{ $t('bill_of_material.amount') }}</th>
                    <th width="150px">{{ $t('actions') }}</th>
                  </tr>

                  </thead>
                  <tbody>
                  <tr v-for="(row, index) in scrap_items" :key="index">
                    <td>
                      <a :id="'popover-target-'+index" href="javascript:;" v-if="row.item" class="d-flex cursor-pointer">
                        <img :src="row.item.image_url" style="width: 70px; height: 70px;" alt="">
                        <div class="pl-2 pr-2 text-dark">
                          <p class="mb-1" v-if="row.item.sku_code">{{ $t('items.sku_code') }}: {{ row.item.sku_code }}</p>
                          <p class="mb-1" v-if="row.item.name">{{ $t('items.name') }}: {{ row.item.name }}</p>
                          <p class="mb-1" v-if="row.item.sale_price">{{ $t('items.sale_price') }}: {{ row.item.sale_price }}</p>
                          <p class="mb-1" v-if="row.item.purchase_price">{{ $t('items.purchase_price') }}: {{ row.item.purchase_price }}</p>
                          <p class="mb-1" v-if="row.item.sum_available_qty">{{ $t('items.sum_available_qty') }}: {{ row.item.sum_available_qty }}</p>
                        </div>
                      </a>
                    </td>
                    <td>
                      <div class="d-flex search-item-group" v-b-tooltip.hover.leftbottom :title="$t('type_at_least_three_letters') + ' ' + $t('bill_of_material.item')">
                        <select name="" v-model="row.f_by" class="custom-select" style="width: 100px;">
                          <option value="name" selected>{{ $t('items.name') }}</option>
                          <option value="sku_code">{{ $t('items.sku_code') }}</option>
                        </select>
                        <multiselect v-model="row.item"
                                     :placeholder="$t('bill_of_material.item')"
                                     :label="row.f_by? row.f_by:'name'"
                                     track-by="id"
                                     :options="scrap_items_repeater_select"
                                     @input="checkScrapItemSelected(index, $event)"
                                     :multiple="false"
                                     :taggable="false"
                                     :show-labels="false"
                                     :show-no-options="false"
                                     :show-no-results="false">
                        </multiselect>
                      </div>

                    </td>

                    <td><input v-model="row.quantity" type="number" class="form-control"></td>
                    <td>
                      <b-form-checkbox size="lg" v-model="row.is_waste" name="check-button" switch></b-form-checkbox>
                    </td>

                    <td>
                      <multiselect v-model="row.unit"  v-b-tooltip.hover.leftbottom :title="$t('type_at_least_three_letters') + ' ' + $t('bill_of_material.unit')"
                                   disabled
                                   :placeholder="$t('bill_of_material.unit')"
                                   label="name"
                                   track-by="id"
                                   :options="units"
                                   :multiple="false"
                                   :taggable="false"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false">
                      </multiselect>
                    </td>

                    <td><input disabled v-model="row.price" type="number" class="form-control"></td>
                    <td><input disabled v-model="row.amount" type="number" class="form-control"></td>


                    <td>
                      <v-icon style="color: #dc3545;" small @click="removeRepeaterScrapItem(index)">mdi-delete</v-icon>
                      <button class="btn btn-primary btn-sm m-2" @click="showModal(index)">{{ $t('bill_of_material.details') }}</button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-md-12">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-4 mb-5">
                    <label>{{ $t('bill_of_material.process_loss_rate') }}</label>
                    <input type="number" disabled min="0" v-model="data.process_loss_rate" class="form-control" :class="validation && validation.process_loss_rate ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.process_loss_rate" class="fv-plugins-message-container invalid-feedback">
                                              {{ validation.process_loss_rate[0] }}
                                          </span>
                  </div>
                  <div class="col-lg-4 mb-5">
                    <label>{{ $t('bill_of_material.process_loss_rate_system') }}</label>
                    <input type="number" disabled min="0" v-model="process_loss_rate_system" class="form-control" :class="validation && validation.process_loss_rate_system ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.process_loss_rate_system" class="fv-plugins-message-container invalid-feedback">
                                              {{ validation.process_loss_rate_system[0] }}
                                          </span>
                  </div>
                  <div class="col-lg-4 mb-5">
                    <label>{{ $t('bill_of_material.process_loss_quantity') }}</label>
                    <input type="number" disabled min="0" v-model="data.process_loss_quantity" class="form-control" :class="validation && validation.process_loss_quantity ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.process_loss_quantity" class="fv-plugins-message-container invalid-feedback">
                                              {{ validation.process_loss_quantity[0] }}
                                          </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab :title="$t('bill_of_material.cost')" @click="toggleTab('cost')" :disabled="!isEditing">

          <div class="row mt-5">
            <div class="col-md-12">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-4 mb-5">
                    <label>{{ $t('bill_of_material.operation_cost') }}</label>
                    <input type="number" min="0" v-model="data.operation_cost" class="form-control" :class="validation && validation.operation_cost ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.operation_cost" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.operation_cost[0] }}
                                    </span>
                  </div>
                  <div class="col-lg-4 mb-5">
                    <label>{{ $t('bill_of_material.material_cost') }}</label>
                    <input type="number" min="0" v-model="data.material_cost" class="form-control" :class="validation && validation.material_cost ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.material_cost" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.material_cost[0] }}
                                    </span>
                  </div>
                  <div class="col-lg-4 mb-5">
                    <label>{{ $t('bill_of_material.workstation_cost') }}</label>
                    <input type="number" min="0" v-model="data.workstation_cost" class="form-control" :class="validation && validation.workstation_cost ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.workstation_cost" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.workstation_cost[0] }}
                                    </span>
                  </div>
                  <div class="col-lg-4 mb-5">
                    <label>{{ $t('bill_of_material.scrap_waste_cost') }}</label>
                    <input type="number" min="0" v-model="data.scrap_waste_cost" class="form-control" :class="validation && validation.scrap_waste_cost ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.scrap_waste_cost" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.scrap_waste_cost[0] }}
                                    </span>
                  </div>
                  <div class="col-lg-4 mb-5">
                    <label>{{ $t('bill_of_material.scrap_not_waste_cost') }}</label>
                    <input type="number" min="0" v-model="data.scrap_not_waste_cost" class="form-control" :class="validation && validation.scrap_not_waste_cost ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.scrap_not_waste_cost" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.scrap_not_waste_cost[0] }}
                                    </span>
                  </div>
                  <div class="col-lg-4 mb-5">
                    <label>{{ $t('bill_of_material.total_cost') }}</label>
                    <input type="number" min="0" v-model="data.total_cost" class="form-control" :class="validation && validation.total_cost ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.total_cost" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.total_cost[0] }}
                                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-lg-12">
              <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                <h6 class="mt-2">{{ $t('bill_of_material.extra_cost') }}</h6>
                <button type="button" class="btn btn-primary" @click="addRepeaterCost"><i class="fas fa-plus"></i>{{ $t('add_more') }}</button>
              </div>
              <div class="table-responsive bg-white">
                <table class="table table-row-bordered table-custom-padding" @keyup.alt.enter="addRepeaterCost" @keyup.alt.46="removeRepeaterCost">
                  <thead>
                  <tr>
                    <th width="300px">
                      <div class="d-flex justify-content-between">
                        <span>{{ $t('bill_of_material.type') }}</span>
                        <a href="/settings/general-group" target="_blank" class="btn btn-primary btn-sm p-1">
                          <i class="fa fa-plus add-new"></i>
                        </a>
                      </div>
                    </th>
                    <th width="200px">{{ $t('bill_of_material.title') }}</th>
                    <th width="150px">{{ $t('bill_of_material.currency') }}</th>
                    <th width="200px">{{ $t('bill_of_material.notes') }}</th>
                    <th width="150px">{{ $t('bill_of_material.amount') }}</th>
                    <th width="150px">{{ $t('bill_of_material.amount') }} ({{ currency_cost_name }})</th>
                    <th>{{ $t('actions') }}</th>
                  </tr>

                  </thead>
                  <tbody>
                  <tr v-for="(row, index) in cost_items" :key="index">
                    <td>
                      <select name="" v-model="row.general_group_id" class="custom-select">
                        <option v-for="row in general_groups" :value="row.id" :key="row.id">{{ row.name }}</option>
                      </select>
                    </td>
                    <td><input v-model="row.title" type="text" class="form-control"></td>
                    <td>
                      <select name="" v-model="row.currency_id" class="custom-select">
                        <option v-for="row in currency_system_list" :value="row.id" :key="row.id">{{ row.name }}</option>
                      </select>
                    </td>
                    <td><textarea v-model="row.notes" class="form-control"></textarea></td>
                    <td><input v-model="row.amount" @input="calcAmountForBom(index)" type="number" min="0" class="form-control"></td>
                    <td>{{ row.amount_for_bom }}</td>


                    <td>
                      <v-icon style="color: #dc3545;" small @click="removeRepeaterCost(index)">mdi-delete</v-icon>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
          <div class="row mt-5">
            <div class="col-md-12">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('bill_of_material.net_total_cost') }}</label>
                    <input type="number" min="0" v-model="data.net_total_cost" class="form-control" :class="validation && validation.net_total_cost ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.net_total_cost" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.net_total_cost[0] }}
                                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </b-tab>

      </b-tabs>


      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="reset" class="btn btn-primary mr-2" @click="save(1, 0)">{{ $t('save_as_draft') }}</button>
            <button type="reset" class="btn btn-outline-dark mr-2" @click="checkMaterialValidation()">{{ $t('material_validation') }}</button>
            <button type="reset" class="btn btn-warning mr-2" @click="save(2, 0)">{{ $t('save_as_confirm') }}</button>
          </div>
        </div>
      </div>

    </div>

    <b-modal ref="modal" size="lg" hide-footer :title="$t('bill_of_material.details')">
      <div>
        <textarea v-if="scrap_items[scrap_item_index]" rows="5" v-model="scrap_items[scrap_item_index].note" class="w-100 form-control"></textarea>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import {mapGetters, mapState} from "vuex";

export default {
  name: "Form-bill-of-material",
  data() {
    return {
      mainRoute: 'manufacturers/bill_materials',
      subRoute: 'manufacturers/bill_material',
      mainRouteDependency: 'base/dependency',

      tab_name: 'basic_information',


      data: {
        name: null,
        item_id: null,
        allow_alternative_item: false,
        is_default: false,
        is_active: true,
        unit_id: null,
        quantity: null,
        actual_quantity: null,
        description: null,


        rate_sub_assembly_based_bom: false,
        allow_multi_inventory: false,
        company_id: null,
        inventory_id: null,
        branch_id: null,
        currency_id: null,
        rate_material: null,
        with_operation: false,
        transfer_manual: null,
        routing_id: null,
        exchange_rate: null,
        process_loss_rate: 0,
        process_loss_quantity: 0,

        operation_cost: 0,
        material_cost: 0,
        workstation_cost: 0,
        scrap_waste_cost: 0,
        scrap_not_waste_cost: 0,
        total_cost: 0,
        net_total_cost: 0,
        status: 1,
      },

      idEditing: this.$route.params.id ? this.$route.params.id : null,
      isEditing: false,

      validation: null,
      unit_default: null,

      unit: null,
      units: [],

      item: null,
      items: [],
      items_repeater_select: [],
      f_by: 'name',

      inventory: null,
      inventories: [],

      branch: null,
      branches: [],

      currencies: [],
      rate_materials: [],
      transfer_manuals: [],

      routing_obj: null,
      routing_list: [],


      sub_operations: [],
      sub_operations_repeater: {id: null, sequence_id: null, operation: null, operation_management_id: null, workstation: null, workstation_id: null, operation_time_minutes: null, description: null, cost: null, use_delete: true, operation_price_per_hour: null},

      workstations: [],
      operations: [],

      last_sequence: 0,
      company_name: null,

      sub_items: [],
      items_manufacture: [],
      sub_item_repeater: {
        id: null, item_id: null, item: null, unit: null, unit_id: null,
        origin_quantity: null, required_quantity: null, inventory: null, inventory_id: null, reserved: null, price: null, amount: null,
      },


      scrap_items: [],
      scrap_item_repeater: {id: null, item_id: null, item: null, unit: null, unit_id: null, quantity: null, price: null, amount: null, is_waste: false, note: null, inventory: null},
      scrap_items_repeater_select: [],

      scrap_item_index: 0,
      process_loss_rate_system: 0,


      general_groups: [],
      cost_items: [],
      cost_item_repeater: {id: null, general_group_id: null, title: null, currency_id: null, notes: null, amount: null, amount_for_bom: null},

      status_list: [],
      is_validate_material: 0, // 1 => validation quantity, 2 => save data
      material_validations: [],
      currency_cost_name: null,
      currency_system_list: [],
    };
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),

  },
  watch: {

    item: function (val) {
      if (val)
        this.data.item_id = val.id;
      else
        this.data.item_id = null;
    },
    unit: function (val) {
      if (val)
        this.data.unit_id = val.id;
      else
        this.data.unit_id = null;
    },
    inventory: function (val) {
      if (val) {
        this.data.inventory_id = val.id;
      } else {
        this.data.inventory_id = null;
      }
    },
    branch: function (val) {
      if (val) {
        this.data.branch_id = val.id;
      } else {
        this.data.branch_id = null;
      }
    },
    routing_obj: function (val) {
      if (val) {
        this.data.routing_id = val.id;
        // this.getRoutingRepeater();
      } else {
        this.data.routing_id = null;
      }
    },
    sub_operations: {
      handler(val) {
        if (val.length > 0) {
          this.sub_operations.map((row) => {
            row.cost = ((row.operation_price_per_hour ? parseFloat(row.operation_price_per_hour) : 0) * (row.operation_time_minutes ? parseInt(row.operation_time_minutes) / 60 : 0)).toFixed(2);
          })
          this.data.workstation_cost = this.$_.sumBy(this.sub_operations, function (row) {
            return row.cost ? parseFloat(row.cost) : 0;
          });

        }
      },
      deep: true
    },
    sub_items: {
      handler(val) {
        this.scrap_items_repeater_select = [];
        if (val.length > 0) {
          this.sub_items.map((row) => {
            row.amount = ((row.price ? parseFloat(row.price) : 0) * (row.required_quantity ? parseFloat(row.required_quantity) : 0)).toFixed(2);
          });
          this.sub_items.forEach((row) => {
            if (row.item) {
              let _item = {...row.item};
              _item.inventory = row.inventory;
              _item.unit = row.unit;
              _item.inner_price = row.price;
              this.scrap_items_repeater_select.push({..._item});
            }
          });
          this.data.material_cost = this.$_.sumBy(this.sub_items, function (row) {
            return row.amount ? parseFloat(row.amount) : 0;
          });
        }
      },
      deep: true
    },
    scrap_items: {
      handler(val) {
        if (val.length > 0) {
          this.scrap_items.map((row) => {
            row.amount = ((row.price ? parseFloat(row.price) : 0) * (row.quantity ? parseFloat(row.quantity) : 0)).toFixed(2);
          });
          this.data.process_loss_rate = this.$_.sumBy(this.scrap_items, (row) => {
            if (row.is_waste)
              return row.amount ? parseFloat(row.amount) : 0;

          });
          let _process_loss_rate_system = (this.data.process_loss_rate / (this.data.exchange_rate ? parseFloat(this.data.exchange_rate) : 0));
          this.process_loss_rate_system = _process_loss_rate_system ? _process_loss_rate_system.toFixed(2) : 0;

          this.data.process_loss_quantity = this.$_.sumBy(this.scrap_items, (row) => {
            if (row.is_waste)
              return row.quantity ? parseInt(row.quantity) : 0;
          });

          this.data.scrap_waste_cost = this.$_.sumBy(this.scrap_items, (row) => {
            if (row.is_waste)
              return row.amount ? parseFloat(row.amount) : 0;
          });


          this.data.scrap_not_waste_cost = this.$_.sumBy(this.scrap_items, (row) => {
            if (!row.is_waste)
              return row.amount ? parseFloat(row.amount) : 0;
            else
              return 0;
          });

        }
      },
      deep: true
    },

    "data.currency_id": function (val) {
      if (val) {
        this.getExchangeRate(val);
        let _currency = this.currencies.find((row) => row.id == val);
        if (_currency) {
          this.currency_cost_name = _currency.name;
          let _currency_system = this.currency_system_list.find((row) => row.is_system == true);
          this.currency_system_list = [];
          this.currency_system_list.push(_currency_system);
          if (_currency_system && _currency.id != _currency_system.id) {
            this.currency_system_list.push(_currency);
          }
        } else {
          this.currency_cost_name = null;
        }
      } else {
        this.data.exchange_rate = null;
        this.currency_cost_name = null;
      }
    },
    "data.operation_cost": function () {
      this.totalSummation();
    },
    "data.material_cost": function () {
      this.totalSummation();
    },
    "data.workstation_cost": function () {
      this.totalSummation();
    },
    "data.scrap_waste_cost": function () {
      this.totalSummation();
    },
    "data.total_cost": function () {
      this.totalSummation();
    },
    cost_items: {
      handler(val) {
        if (val.length > 0) {
          this.totalSummation();
        }
      },
      deep: true
    },
  },
  methods: {
    toggleTab(tab_name) {
      this.tab_name = tab_name
    },
    save(status, is_validate_material) {
      this.material_validations = [];
      this.is_validate_material = is_validate_material;
      if (this.isEditing) {
        this.update(status);
      } else {
        this.create(status);
      }
    },

    checkMaterialValidation() {
      ApiService.patch(`${this.subRoute}/validate_material`, {
        sub_items: this.sub_items,
        is_validate_material: this.is_validate_material,
      }).then((response) => {
        this.material_validations = null;
        this.$successAlert(response.data.message);
      }).catch((error) => {
        this.$errorAlert(error);
        this.material_validations = error.response ? error.response.data.material_errors : null;
      });
    },
    create(status) {
      if (status) {
        this.data.status = status;
      }
      ApiService.post(`${this.mainRoute}`, {
        ...this.data,
        operations: this.sub_operations,
        sub_items: this.sub_items,
        scrap_items: this.scrap_items,
        cost_items: this.cost_items,
        is_validate_material: this.is_validate_material,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);

            if (this.is_validate_material == 0)
              this.$router.push({name: 'bill-of-material.index'});
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
            this.material_validations = error.response ? error.response.data.material_errors : null;
          });
    },


    update(status) {
      if (status) {
        this.data.status = status;
      }
      ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
        ...this.data,
        operations: this.sub_operations,
        sub_items: this.sub_items,
        scrap_items: this.scrap_items,
        cost_items: this.cost_items,
        is_validate_material: this.is_validate_material,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);

        if (this.is_validate_material == 0)
          this.$router.push({name: 'bill-of-material.index'});

      })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
            this.material_validations = error.response ? error.response.data.material_errors : null;
          });
    },

    async getData() {

      ApiService.get(`${this.mainRoute}/${this.$route.params.id}`).then((response) => {

        if (this.$route.params.is_clone == 'clone') {
          this.isEditing = false;
        } else {
          this.isEditing = true;
        }


        this.data.name = response.data.data.name;
        this.data.item_id = response.data.data.item_id;
        this.getItemsRepeaterRows(response.data.data.item_id, false);
        this.data.allow_alternative_item = response.data.data.allow_alternative_item;
        this.data.is_default = response.data.data.is_default;
        this.data.is_active = response.data.data.is_active;
        this.data.unit_id = response.data.data.unit_id;
        this.data.quantity = response.data.data.quantity;
        this.data.actual_quantity = response.data.data.actual_quantity;
        this.data.description = response.data.data.description;
        this.data.rate_sub_assembly_based_bom = response.data.data.rate_sub_assembly_based_bom;
        this.data.allow_multi_inventory = response.data.data.allow_multi_inventory;
        this.data.company_id = response.data.data.company_id;
        this.data.inventory_id = response.data.data.inventory_id;
        this.data.branch_id = response.data.data.branch_id;
        this.data.currency_id = response.data.data.currency_id;
        this.data.rate_material = response.data.data.rate_material;
        this.data.with_operation = response.data.data.with_operation;
        this.data.transfer_manual = response.data.data.transfer_manual;
        this.data.routing_id = response.data.data.routing_id;
        this.data.status = response.data.data.status;

        this.unit = response.data.data.unit;
        this.item = response.data.data.item;
        this.inventory = response.data.data.inventory;
        this.branch = response.data.data.branch;
        this.routing_obj = response.data.data.routing;
        this.company_name = response.data.data.company ? response.data.data.company.name : null;
        this.data.exchange_rate = response.data.data.exchange_rate;
        this.data.process_loss_rate = response.data.data.process_loss_rate;
        this.data.process_loss_quantity = response.data.data.process_loss_quantity;
        this.process_loss_rate_system = response.data.data.process_loss_rate / response.data.data.exchange_rate;


        if (response.data.data.operations && response.data.data.operations.length > 0) {
          this.sub_operations = response.data.data.operations;
          response.data.data.operations.forEach((row) => {
            if (row.sequence_id > this.last_sequence) {
              this.last_sequence = row.sequence_id;
            }
          });

          if (this.$route.params.is_clone == 'clone') {
            this.sub_operations = response.data.data.operations.map((row) => {
              row.id = null;
              return row;
            });
          }

        } else {
          this.addRepeater();
        }

        if (response.data.data.sub_items && response.data.data.sub_items.length > 0) {
          this.sub_items = response.data.data.sub_items;
          if (this.$route.params.is_clone == 'clone') {
            this.sub_items = response.data.data.sub_items.map((row) => {
              row.id = null;
              return row;
            });
          }

        } else {
          this.addRepeaterItem();
        }
        if (response.data.data.scrap_items && response.data.data.scrap_items.length > 0) {
          this.scrap_items = response.data.data.scrap_items.map((row) => {
            row.id = null;
            return row;
          });
        } else {
          this.addRepeaterScrapItem();
        }

        if (response.data.data.cost_items && response.data.data.cost_items.length > 0) {
          this.cost_items = response.data.data.cost_items.map((row) => {
            row.id = null;
            return row;
          });
        } else {
          this.addRepeaterCost();
        }

        this.data.operation_cost = response.data.data.operation_cost;
        this.data.material_cost = response.data.data.material_cost;
        this.data.workstation_cost = response.data.data.workstation_cost;
        this.data.scrap_waste_cost = response.data.data.scrap_waste_cost;
        this.data.scrap_not_waste_cost = response.data.data.scrap_not_waste_cost;
        this.data.total_cost = response.data.data.total_cost;
        this.data.net_total_cost = response.data.data.net_total_cost;
      });
    },

    getItemsManufacture(f_by = null, filter = null) {
      if (filter && filter.length >= 3 && f_by)
        ApiService.get(this.mainRouteDependency + "/items_manufacture", {params: {[f_by]: filter}}).then((response) => {
          this.items_manufacture = response.data.data;
        });
    },
    getItems(f_by = null, filter = null) {
      if (filter && filter.length >= 3 && f_by)
        ApiService.get(this.mainRouteDependency + "/items", {params: {[f_by]: filter}}).then((response) => {
          this.items = response.data.data;
        });
    },
    async getUnits() {
      await ApiService.get(this.mainRouteDependency + "/units").then((response) => {
        this.units = response.data.data;
        // let default_unit = response.data.data.filter(row => row.is_default == 1);
        // if (default_unit[0]) {
        //   default_unit = default_unit[0];
        //   if (!this.idEditing) {
        //     this.unit = default_unit;
        //   }
        // }
      });
    },
    async getDefaultUnit() {
      await ApiService.get(`${this.mainRouteDependency}/default-unit`).then((response) => {
        this.unit_default = response.data.data;
        this.unit = response.data.data;
      });
    },
    getInventories() {
      ApiService.get(this.mainRouteDependency + `/inventories`).then((response) => {
        this.inventories = response.data.data;
      });
    },
    getBranches() {
      ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
      });
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    getRateMaterials() {
      ApiService.get(this.mainRouteDependency + "/rate_material").then((response) => {
        this.rate_materials = response.data.data;
      });
    },
    getTransferManuals() {
      ApiService.get(this.mainRouteDependency + "/transfer_manual").then((response) => {
        this.transfer_manuals = response.data.data;
      });
    },
    getRouting() {
      ApiService.get(this.mainRouteDependency + "/all_routing").then((response) => {
        this.routing_list = response.data.data;
      });
    },

    getWorkstations() {
      ApiService.get(this.mainRouteDependency + "/workstations").then((response) => {
        this.workstations = response.data.data;
      });
    },
    getOperations() {
      ApiService.get(this.mainRouteDependency + "/operations").then((response) => {
        this.operations = response.data.data;
      });
    },
    getGeneralGroups() {
      ApiService.get(this.mainRouteDependency + "/general_groups").then((response) => {
        this.general_groups = response.data.data;
      });
    },
    getCompanyName() {
      ApiService.get("/getUserPersonal").then((response) => {
        this.company_name = response.data.data.company ? response.data.data.company.name : null;
      });
    },
    getDefaultCurrency() {
      ApiService.get(this.mainRouteDependency + "/default-currency").then((response) => {
        this.data.currency_id = response.data.data.id;
      });
    },
    getDefaultBranch() {
      ApiService.get(this.mainRouteDependency + "/default-branch").then((response) => {
        this.branch = response.data.data;
      });
    },
    getDefaultInventory() {
      ApiService.get(this.mainRouteDependency + "/default-inventory").then((response) => {
        this.inventory = response.data.data;
        this.sub_item_repeater.inventory = response.data.data;
        if (this.sub_items[0]) {
          this.sub_items[0].inventory = response.data.data;
        }
      });
    },
    getExchangeRate(id) {
      ApiService.get(`${this.mainRouteDependency}/exchange_rate/` + id).then((response) => {
        this.data.exchange_rate = response.data.data.initial_val;
      });
    },
    getSystemCurrency() {
      ApiService.get(`${this.mainRouteDependency}/currency-system`).then((response) => {
        let _currency = response.data.data;
        _currency.is_system = true;
        this.currency_system_list.push(_currency);

      });
    },
    addRepeater() {
      this.last_sequence = this.last_sequence + 1;
      this.sub_operations_repeater.sequence_id = this.last_sequence;
      this.sub_operations.push(this.sub_operations_repeater);
      this.sub_operations_repeater = {id: null, sequence_id: null, operation: null, operation_management_id: null, workstation: null, workstation_id: null, operation_time_minutes: null, description: null, cost: null, use_delete: true, operation_price_per_hour: null,};
    },
    removeRepeater(index) {
      if (!this.sub_operations[index].id) {
        this.last_sequence = this.last_sequence - 1;
      }
      if (this.sub_operations.length > 1)
        this.sub_operations.splice(index, 1);
    },
    selectItem(index, type) {
      if (type == 'operation')
        this.sub_operations[index].operation_management_id = this.sub_operations[index].operation ? this.sub_operations[index].operation.id : null;
      else if (type == 'workstation')
        this.sub_operations[index].workstation_id = this.sub_operations[index].workstation ? this.sub_operations[index].workstation.id : null;
    },
    getRoutingRepeater() {
      setTimeout(() => {
        if (this.data.routing_id)
          ApiService.get(this.mainRouteDependency + "/routing/" + this.data.routing_id).then((response) => {
            this.last_sequence = 0;
            this.sub_operations = response.data.data.map((row) => {
              this.last_sequence = this.last_sequence + 1;
              return {
                id: null,
                sequence_id: this.last_sequence,
                operation: row.operation,
                operation_management_id: row.operation_management_id,
                workstation: row.workstation,
                workstation_id: row.workstation_id,
                operation_time_minutes: row.operation_time_minutes,
                description: row.description,
                cost: null,
                use_delete: false

              };
            });
          });
        else
          this.sub_operations = [];
      }, 100)

    },

    addRepeaterItem() {
      this.sub_item_repeater.unit = this.unit_default;
      this.sub_items.push(this.sub_item_repeater);
      this.sub_item_repeater = {
        id: null, item_id: null, item: null, unit: null, unit_id: null,
        origin_quantity: null, required_quantity: null, inventory: null, inventory_id: null, reserved: null, price: null, amount: null,
      };
    },
    removeRepeaterItem(index = 0) {
      if (this.sub_items.length > 1)
        this.sub_items.splice(index, 1);
    },
    getItemsForRepeater(item) {
      if (item && item.id) {
        this.getItemsRepeaterRows(item.id);
        // this.getItemsRepeaterSelect(item.id);
      }

    },
    getItemsRepeaterRows(id, set_in_repeater = true) {
      ApiService.get(this.subRoute + "/items_with_alternatives/" + id).then((response) => {
        if (set_in_repeater) {
          this.sub_items = [];
          response.data.data.forEach((row) => {
            if (row.is_sub_item) {
              row.unit = this.unit;
              row.inventory = this.inventory;
              this.sub_items.push(row);
            }
          });
        }


        this.getItemsRepeaterSelect(response.data.data);
      });
    },
    getItemsRepeaterSelect(items) {
      this.items_repeater_select = [];
      items.forEach((row) => {
        if (row.item)
          this.items_repeater_select.push(row.item);
      });
    },
    checkItemSelected(index, event) {

      let item_found = null;
      this.sub_items.forEach((row, _index) => {

        if (_index != index && row.item.id == event.id) {
          item_found = row;
        }
      });
      if (item_found) {
        this.sub_items[index].item = null;
      }
    },

    getItemPrice(event) {
      let ids = [];
      let _rate_material = event.target.value;

      this.sub_items.forEach((row) => {
        if (row.item)
          ids.push(row.item.id);
      });

      if (ids.length > 0)
        ApiService.get(this.subRoute + "/price_for_items", {params: {ids: ids, rate_material: _rate_material}}).then((response) => {
          let _res = response.data.data;
          this.sub_items.map((row) => {
            if (row.item) {
              let _found = _res.find((rs) => rs.id == row.item.id);
              if (_found)
                row.price = _found.price;
            }
          });
        });
    },
    addRepeaterScrapItem() {
      this.scrap_items.push(this.scrap_item_repeater);
      this.scrap_item_repeater = {id: null, item_id: null, item: null, unit: null, unit_id: null, quantity: null, price: null, amount: null, is_waste: false, note: null, inventory: null};
    },
    removeRepeaterScrapItem(index = 0) {
      if (this.scrap_items.length > 0)
        this.scrap_items.splice(index, 1);
    },
    addRepeaterCost() {
      this.cost_items.push(this.cost_item_repeater);
      this.cost_item_repeater = {id: null, general_group_id: null, title: null, currency_id: null, notes: null, amount: null, amount_for_bom: null};
    },
    removeRepeaterCost(index = 0) {
      if (this.cost_items.length > 0)
        this.cost_items.splice(index, 1);
    },
    checkScrapItemSelected(index, event) {
      let item_found = null;
      this.scrap_items.forEach((row, _index) => {
        if (_index != index && row.item.id == event.id) {
          item_found = row;
        }
      });
      if (item_found) {
        this.scrap_items[index].item = null;
      } else {
        this.scrap_items[index].price = event.inner_price;
        this.scrap_items[index].unit = event.unit;
        this.scrap_items[index].inventory = event.inventory;
      }
    },
    showModal(index) {
      this.scrap_item_index = index;
      this.$refs['modal'].show()
    },
    hideModal() {
      this.scrap_item_index = 0;
      this.$refs['modal'].hide();
    },
    totalSummation() {
      let _operation_cost = this.data.operation_cost ? parseFloat(this.data.operation_cost) : 0;
      let _material_cost = this.data.material_cost ? parseFloat(this.data.material_cost) : 0;
      let _workstation_cost = this.data.workstation_cost ? parseFloat(this.data.workstation_cost) : 0;
      let _scrap_waste_cost = this.data.scrap_waste_cost ? this.data.scrap_waste_cost : 0;
      this.data.total_cost = (_operation_cost + _material_cost + _workstation_cost - _scrap_waste_cost).toFixed(2);

      let _sum_repeater_cost = 0;
      _sum_repeater_cost = this.$_.sumBy(this.cost_items, function (row) {
        return row.amount_for_bom ? parseFloat(row.amount_for_bom) : 0;
      });

      this.data.net_total_cost = (parseFloat(this.data.total_cost) + _sum_repeater_cost).toFixed(2);
    },
    getStatusList() {
      ApiService.get(this.mainRouteDependency + "/bill_status").then((response) => {
        this.status_list = response.data.data;
      });
    },
    calcAmountForBom(index) {
      if (this.cost_items[index].currency_id == this.data.currency_id) {
        this.cost_items[index].amount_for_bom = this.cost_items[index].amount;
      } else {
        let _amount = this.cost_items[index].amount ? parseFloat(this.cost_items[index].amount) : 0;
        let _exchange_rate = this.data.exchange_rate ? parseFloat(this.data.exchange_rate) : 0;
        this.cost_items[index].amount_for_bom = _amount * _exchange_rate;
      }
    },

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.bill_of_material"), route: '/manufactories/bill-of-material'}, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);

    this.getUnits();
    this.getInventories();
    this.getBranches();
    this.getCurrencies();
    this.getRateMaterials();
    this.getTransferManuals();
    this.getRouting();
    this.getWorkstations();
    this.getOperations();
    this.getGeneralGroups();
    this.getStatusList();
    this.getSystemCurrency();


    if (this.idEditing) {
      this.getData();
    } else {
      this.getDefaultCurrency();
      this.getDefaultBranch();
      this.getDefaultInventory();

      this.addRepeater();
      let promise = this.getDefaultUnit();
      Promise.all([promise]).then(() => {
        this.addRepeaterItem();
      });
      this.addRepeaterScrapItem();
      this.addRepeaterCost();
      this.getCompanyName();
    }

  },
}
</script>

<style scoped>

</style>